<template>
    <div>
    <div style="position: fixed; top: 55px; right: 10px; z-index: 1000">
      <div id="wrappers">
        <div class="input-data">
          <input
            type="text"
            v-model="Text"
            @keyup.enter="GetCYClass()"
            required
          />
          <div class="underline">
            <i
              class="el-icon-circle-close"
              v-show="Text.length > 0"
              style="
                position: absolute;
                top: -30px;
                right: 60px;
                color: #9fa1a7;
              "
              @click="Text = ''"
            ></i>
            <el-button
              type="primary"
              style="position: absolute; top: -35px; right: 1px"
              size="mini"
              icon="el-icon-search"
              @click="GetCYClass()"
            ></el-button>
          </div>
          <label>{{ $t('All.品名') }}/{{ $t('All.料号') }}</label>
        </div>
      </div>
    </div>
    <div>
      <template>
        <el-table :data="formData" style="width: 100%">
        <el-table-column prop="Date" :label="$t('All.日期')" width="160">
        </el-table-column>
        <el-table-column prop="Product_name" :label="$t('All.品名')"> </el-table-column>
        <el-table-column prop="Gg" :label="$t('All.规格')"> </el-table-column>
        <el-table-column prop="Item_no" :label="$t('All.料号')"> </el-table-column>
        <el-table-column prop="Lot" :label="$t('All.Lot号')"> </el-table-column>
        <el-table-column prop="Gd" :label="$t('All.工单号')"> </el-table-column>
        <el-table-column prop="Cs" :label="$t('All.厂商')"> </el-table-column>
        <el-table-column prop="Bb" :label="$t('All.班别')"> </el-table-column>
        <el-table-column prop="Lot" label="LoT"> </el-table-column>
        <el-table-column align="center" prop="Cdate" :label="$t('All.生产日期')">
          <template slot-scope="scope">
            {{scope.row.Cdate|formatDate('yyyy-MM-dd')}}
            </template>
        </el-table-column>
        <el-table-column prop="Gd" :label="$t('All.工单号')"> </el-table-column>
        <el-table-column prop="Ys" :label="$t('All.颜色')"> </el-table-column>
        <el-table-column prop="Yd" :label="$t('All.硬度')"> </el-table-column>
        <el-table-column prop="PVC" label="PVC"> </el-table-column>
        <el-table-column prop="Jyy" :label="$t('All.操作员')"> </el-table-column>
        <el-table-column prop="Zl" :label="$t('All.总数量')"> </el-table-column>
        <el-table-column prop="DZl" :label="$t('All.当前数量')"> </el-table-column>
        <el-table-column align="center" :label="$t('All.状态')" width="120">
          <template slot-scope="scope" >
              <el-tag :type="scope.row.State === 0?'success':scope.row.State === 2?'warning':scope.row.State === 3?'primary':'danger'" effect="dark">{{ scope.row.State === 0?$t('All.有效'):scope.row.State === 2?$t('All.锁') + scope.row.Order_no:scope.row.State === 3?$t('All.完') + scope.row.Order_no:$t('All.失效') }}</el-tag>
            </template>
          </el-table-column>
        <el-table-column align="center" prop="Type" :label="$t('All.操作类型')" width="110">
          <template slot-scope="scope">
              <el-tag>{{ scope.row.Type === 0?$t('All.生产'):scope.row.Type === 2?$t('All.拆包'):scope.row.Type === 3?$t('All.退料'):scope.row.Type === 1?$t('All.补PASS单'):'' }}</el-tag>
            </template>
        </el-table-column>
        <el-table-column fixed="right" align="center" width="80" prop="Type" :label="$t('All.操作')">
          <template slot-scope="scope">
              <el-button size="mini" style="text-align: right;" type="danger" :disabled="!(scope.row.State === 0 && $store.state.Login.Jurisdiction <= 4 && ($store.state.Login.Department === 'SCYB' || $store.state.Login.Department === 'CT' || $store.state.Login.Department === 'All'))" @click="Delbs(scope.row)">Del</el-button>
            </template>
        </el-table-column>
      </el-table>
      </template>
    </div>
    <el-pagination
    style="text-align: center; padding: 20px;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageindex"
      :page-sizes="[10, 100, 200, 300]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>
<script>
export default {
  components: {},
  props: [],
  data () {
    return {
      formData: [],
      total: 0,
      page_size: 10,
      pageindex: 1,
      Text: ''
    }
  },
  filters: {
    formatDate: function (value, args) {
      var dt = new Date(value)
      if (args === 'yyyy-M-d') { // yyyy-M-d
        const year = dt.getFullYear()
        const month = dt.getMonth() + 1
        const date = dt.getDate()
        return `${year}-${month}-${date}`
      } else if (args === 'yyyy-M-d H:m:s') { // yyyy-M-d H:m:s
        const year = dt.getFullYear()
        const month = dt.getMonth() + 1
        const date = dt.getDate()
        const hour = dt.getHours()
        const minute = dt.getMinutes()
        const second = dt.getSeconds()
        return `${year}-${month}-${date} ${hour}:${minute}:${second}`
      } else if (args === 'yyyy-MM-dd') { // yyyy-MM-dd
        const year = dt.getFullYear()
        const month = (dt.getMonth() + 1).toString().padStart(2, '0')
        const date = dt.getDate().toString().padStart(2, '0')
        return `${year}-${month}-${date}`
      } else { // yyyy-MM-dd HH:mm:ss
        const year = dt.getFullYear()
        const month = (dt.getMonth() + 1).toString().padStart(2, '0')
        const date = dt.getDate().toString().padStart(2, '0')
        const hour = dt.getHours().toString().padStart(2, '0')
        const minute = dt.getMinutes().toString().padStart(2, '0')
        const second = dt.getSeconds().toString().padStart(2, '0')
        return `${year}-${month}-${date} ${hour}:${minute}:${second}`
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.GetZLClass()
  },
  mounted () {},
  methods: {
    async GetZLClass () {
      const intfs = {
        Pageindex: this.pageindex,
        PageSize: this.page_size,
        UserData: this.$store.state.Login,
        Text: this.Text
      }
      const { data: res } = await this.$http.post(
        '/api/Semifinished/GetZLClass',
        intfs
      )
      if (res.status !== 200) return this.$message.error(res.msg)
      this.total = res.response.TotalCount
      this.formData = res.response.ZLClass
    },
    async Delbs (row) {
      this.$confirm(this.$t('All.是否删除此节点'), this.$t('All.提示'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(async () => {
          const intfs = {
            Id: row.Id,
            Serialcode: row.Serialcode,
            UserData: this.$store.state.Login,
            Type: 'ZL'
          }
          const { data: res } = await this.$http.post(
            '/api/Semifinished/Delbs',
            intfs
          )
          if (res.status !== 200) return this.$message.error(res.msg)
          this.$message.success(res.msg)
          this.GetZLClass()
        })
        .catch(() => {
        })
    },
    handleSizeChange (val) {
      this.page_size = val
      this.GetZLClass()
    },
    handleCurrentChange (val) {
      this.pageindex = val
      this.GetZLClass()
    }
  }
}

</script>
<style lang="less" scoped>
/** talbe表头置顶**/
/deep/ .el-table {
    overflow: visible;
 }

 /deep/ .el-table__header-wrapper {
    position: sticky;
    top: 0px;
    z-index: 10;
 }
</style>
